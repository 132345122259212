<template>
  <div>
    <div class="reserved-area-page">
      <div class="container">
        <div class="d-xl-flex">
          <div class="reserved-area-page-column">
            <reservedAreaMenu/>
          </div>
          <div class="reserved-area-page-column">
            <p class="section-title policy-title d-none d-xl-block">Politica de Privacidade</p>
            <div class="reserved-area-text-wrapper">
              <div class="reserved-area-text-item" v-for="(item,index) in areaItems" :key="index">
                <p class="reserved-area-text-title">{{ item.title }}</p>
                <div class="reserved-area-text-description">
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from "axios";

export default {
  name: "policy",
  data() {
    return {
      lang: this.$root.siteLang,
      areaItems: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  components: {
    reservedAreaMenu,
  },
  created() {
    this.$root.pageTitle = this.$root.translations.definicoes;

    axios
        .get(process.env.VUE_APP_API_URL + 'areas/17/items?type=cp&with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.areaItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>